.App {
  text-align: center;
  min-height: 100vh;
  padding: 0px 20px 0px 20px;
  /* background: black; */
  position: relative;
  overflow-y: hidden;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-ticker{
  animation: header-tick 15s linear infinite;
}

@keyframes header-tick{
  from {
    transform: translate3d(-58%,0,0);
  }

  to{
    transform: translate3d(58%,0,0);
  }
}

.socialIcon{
  margin: 0 10px;
}

.menubar{
  display: flex;
  background-color: darkmagenta;  
}

.nav-item {
   color: rgb(0, 255, 242);
  }


  .blink {
    animation: blinker 7s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .dropdown .dropdown-menu {
    transition: all 0.5s;
    overflow: hidden;
    transform-origin: top top;
    transform: scale(1, 0);
    display: block;
    left: 109%;
    top: 10%;
    background-image: linear-gradient(#1b193c  10%, gray 90%);
    width: 300px;
  }

  .dropdown:hover .dropdown-menu {
    transform: scale(1);
  }

  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    background-color: rgb(139 84 181);
    color: white;
  }
  .navbar-dark .navbar-nav .nav-link, a.nav-item.dropdown-item {
    border: 3px solid transparent;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;    
    margin-bottom: 3px;
    margin-right: 3px;   
  }

  a.nav-item.dropdown-item:hover {
    background-color: rgb(139 84 181);
    color: white;
  }

  /* span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  } */

  

  
 